<template>
  <div class="login-wrap">
    <div class="header-wrap"></div>
    <div class="login-item-wrap">
      <div class="tab-wrap">
        <div
          v-for="(item, index) in state.tabList"
          :key="index"
          class="tab-item"
          :class="{ 'tab-item-active': state.current === index }"
          @click="clickHnadle(index)"
        >{{ item }}</div>
      </div>
      <div class="form-wrap" v-if="state.current === 0">
        <div class="form-item">
          <MyField v-model="state.mobile" type="number" maxlength="11" label="手机号码" placeholder="请输入手机号码" />
        </div>
        <div class="form-item">
          <MyField v-model="state.captcha" type="number" maxlength="6" label="验证码" placeholder="请输入验证码">
            <div>
              <div class="vertifiy-wrap" v-if="!isCountDown" @click="vertifyHandle">
                <span class="line">|</span>
                <span>获取验证码</span>
              </div>
              <div class="my-countdown" v-else>
                <span class="line">|</span>
                <CountDown @finish="finishHandle" :time="time" format ="ss"/>
                <div class="secends">s</div>
              </div>
            </div>
          </MyField>
        </div>
      </div>
      <div class="form-wrap" v-else>
        <div class="form-item">
          <MyField v-model="state.username" label="用户名" placeholder="请输入用户名" />
        </div>
        <div class="form-item">
          <MyField v-model="state.mm" type="password" label="密码" placeholder="请输入密码" />
        </div>
      </div>
      <div class="auto-login">
        <Checkbox v-model="checked" icon-size="16px" shape="square" />
        <span class="text">90天内自动登录</span>
      </div>
    </div>
    <div class="login-btn" @click="loginHandle">登录</div>
    <div class="registerBox">
      <span class="item" @click="routerTo('/passwordReset')">忘记密码</span> | <span class="item" @click="routerTo('/register')">现在注册</span>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Checkbox, Toast, CountDown } from 'vant'
import MyField from '@/components/my-input/'
import { loginApi, getCaptcha, codeLoginApi } from '@/api/'
import { deleteObjEmptyData } from '@/utils/'
export default {
  components: {
    MyField,
    Checkbox,
    CountDown
  },
  setup () {
    const router = useRouter()
    const state = reactive({
      current: 0,
      tabList: ['验证码登录', '密码登录'],
      username: '',
      mm: '',
      mobile: '',
      captcha: ''
    })
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    const checked = ref(false)
    const isCountDown = ref(false)
    const time = ref(60 * 1000)

    const clickHnadle = (index) => {
      state.current = index
    }

    const finishHandle = () => {
      isCountDown.value = false
    }

    const vertifyHandle = () => {
      if (reg.test(state.mobile)) {
        isCountDown.value = true
        getCaptcha({
          mobile: state.mobile
        }).then(res => {
          console.log(res)
        })
      } else {
        Toast('请输入正确的手机号码')
      }
    }

    const accountLogin = () => {
      const params = {
        username: state.username,
        password: state.mm,
        exp_time: checked.value ? '90' : ''
      }
      if (state.username === '' || state.mm === '') {
        Toast('用户名和密码不能为空')
      } else {
        loginApi(deleteObjEmptyData(params)).then(res => {
          if (res.code === 200) {
            window.localStorage.setItem('token', res.data.token)
            window.localStorage.setItem('userId', res.data.id)
            router.replace('/home')
          }
          if (res.code === 9) {
            Toast(res.message)
          }
        })
      }
    }

    const codeLogin = () => {
      const params = {
        mobile: state.mobile,
        captcha: state.captcha,
        exp_time: checked.value ? '90' : '1'
      }
      if (!reg.test(state.mobile)) {
        Toast('请输入正确的手机号码')
      } else if (state.captcha === '') {
        Toast('验证码能为空')
      } else {
        codeLoginApi(params).then(res => {
          if (res.code === 200) {
            if (res.data.status) {
              window.localStorage.setItem('token', res.data.token)
              window.localStorage.setItem('userId', res.data.id)
              router.replace('/home')
            } else {
              router.push(`/completeInfo?mobile=${state.mobile}`)
            }
          } else {
            Toast(res.message)
          }
        })
      }
    }

    const loginHandle = () => {
      if (state.current === 1) {
        accountLogin()
      } else {
        codeLogin()
      }
    }

    const routerTo = (url) => {
      router.push(url)
    }
    return {
      routerTo,
      state,
      time,
      isCountDown,
      clickHnadle,
      checked,
      loginHandle,
      vertifyHandle,
      finishHandle
    }
  }
}
</script>

<style lang="less" scoped>
.login-wrap {
  width: 100%;
  height: 100%;
  background: #fff;
}
.header-wrap {
  width: 100%;
  height: 162px;
  background: url('../../assets/login-bg.png') no-repeat;
  background-size: cover;
}
.login-item-wrap {
  margin-top: 28px;
  .tab-wrap {
    display: flex;
    padding: 12px 36px 0 36px;
    .tab-item {
      position: relative;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      &:nth-of-type(1) {
        margin-right: 24px;
      }
    }
    .tab-item-active {
      color: #0080FF;
      &::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        width: 22px;
        height: 2px;
        background: #0080FF;
        border-radius: 1px;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: -2px;
        margin:auto;
      }
    }
  }
  .form-wrap {
    padding: 0 36px;
    .form-item {
      margin-top: 36px;
    }
  }
  .auto-login {
    display: flex;
    margin-top: 16px;
    padding-left: 36px;
    .text {
      margin-left: 9px;
      font-size: 14px;
      color: #494E53;
    }
  }
}
.login-btn {
  margin: 52px 36px 18px 36px;
  height: 44px;
  background: #0C86FE;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
}
.vertifiy-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #007FFF;
  white-space: nowrap;
  .line {
    color: #B9C6D3;
    margin-right: 8px;
  }
}
.my-countdown {
  width: 81px;
  display: flex;
  justify-content: center;
  .line {
    color: #B9C6D3;
    margin-right: 8px;
    font-size: 14px;
  }
  .secends {
    color: #B9C6D3;
    margin-left: 4px;
    font-size: 14px;
  }
}
.registerBox {
  margin-top: 48px;
  font-size: 14px;
  font-weight: 400;
  color: #8E9CA9;
  text-align: center;
  .item {
    font-size: 14px;
    font-weight: 400;
    color: #8E9CA9;
    margin: 0px 16px;
  }
  margin: auto;
}
</style>
